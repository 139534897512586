<template lang="pug">
  AppButtonWithIcon(
    title="actions.download"
    icon="download"
    type="download"
    v-bind="$props"
    v-on="$listeners"
  )
</template>

<script>
  export default {
    props: {
      disabled: Boolean
    },

    components: {
      AppButtonWithIcon: () => import("@/components/elements/AppButton/WithIcon")
    }
  }
</script>

<style lang="sass" scoped></style>
